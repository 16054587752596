<template>
    <div>
        <v-sheet
            color="#f9f9f9"
            class="pt-10 pb-16"
        >
            <!-- 읽기 -->
            <div v-if="$route.query.type == 'read'">
                <EstimateRead />
            </div>

            <!-- 수정 -->
            <div v-if="$route.query.type == 'update'">
                <EstimateUpdate />
            </div>
        </v-sheet>
    </div>
</template>
<script>
import EstimateRead from '@/views/estimate/read'
import EstimateUpdate from '@/views/estimate/update'

export default {
    components: {
        EstimateRead,
        EstimateUpdate
    },

    data: () => ({
        // 검색 키워드 선택목록
        select_list: {
            field: [
                "가스난방",
                "건설인력",
                "건설자재",
                "건설기계",
                "건축시공",
                "계측/측량",
                "구조물해체/비계공사",
                "도장방수",
                "전기/기계/설비",
                "상하수도",
                "설계",
                "시설물유지관리",
                "시험기구",
                "안전용품",
                "인테리어",
                "조경",
                "지반조성/포장",
                "토목시공",
                "품질검사",
                "기타"
            ],

            sequence: [
                "최신순",
                "오래된순",
            ]
        },

        // 검색 키워드
        keyword: {
            total: "",
            category: "",
            product_name: "",
            amount: "",
            pay_date: "",
            deadline: ""
        },

        // 순서
        type: "전체",
        sequence: "최신순",

        // 목록
        list: [],

        // 페이징
        page: 1,
        pageCount: 0,
        itemsPerPage: 10,

        key: 0
    }),

    methods: {
        // 첫 목록
        load(){
            this.$http.post('/api/estimate_request/select/list', {
                params: {
                    user_id: this.$store.state.asap_user.user_id
                }
            }).then((res) => {
                this.list = res.data

                this.keyword.total = ""
                this.keyword.category = ""
                this.keyword.product_name = ""
                this.keyword.amount = ""
                this.keyword.pay_date = ""
                this.keyword.deadline = ""
            })
        },

        // 검색
        search(){
            this.$http.post('/api/estimate_request/select/search', {
                params: {
                    total: this.keyword.total,
                    category: this.keyword.category,
                    product_name: this.keyword.product_name,
                    amount: this.keyword.amount,
                    pay_date: this.keyword.pay_date,
                    deadline: this.keyword.deadline
                }
            }).then((res) => {
                this.list = res.data
                this.key++
            })
        },

        // 게시글로 이동
        read(item){
            this.$router.push("/estimate?type=read&id="+item.id)
        }
    }
}
</script>
<style scoped>
::v-deep .v-text-field input {
    margin-left:2px;
}
</style>
<style scoped>
/* 입력칸 */
.v-text-field--outlined >>> fieldset {
    border-color: #E0E0EB;
}

::v-deep .v-text-field input {
    margin-left:4px;
    font-size: 16px;
}

::v-deep .v-pagination__item {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__item--active {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#2196F3 !important;
    color:white !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation {
    width: 38px;
    height: 40px;
    border-radius: 10px;
    background:#DFDFDF !important;
    box-shadow:none;
}

::v-deep .v-pagination__navigation .v-icon{
    color:white;
}
</style>