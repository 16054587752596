<template>
    <v-sheet
        class="pt-16"
        style="padding-bottom:240px;"
        color="#f9f9f9"
    >
        <v-sheet 
            color="white" 
            class="mx-auto"
            width="1200"
            style="position:relative"
        >
            <v-sheet
                width="540"
                height="28"
                class="blue"
                style="position:absolute; top:-14px; left:340px; box-shadow:0px 1px 10px #ccc;"
            ></v-sheet>
            <div class="rule_box pt-10 pa-16">
                <!-- 제목 -->
                <h2
                    class="text-center mb-10 mt-2"
                    style="letter-spacing:2px"
                >
                    개인정보처리방침
                </h2>

                <p>건설나라(이하 "회사"라 함)는 이용자의 개인정보보호를 매우 중요시하며, 이용자가 회사의 ‘건설나라’ 및 관련 서비스(이하 "서비스"라 함)를 이용함과 동시에 온라인상에서 회사에 제공한 개인정보가 보호 받을 수 있도록 최선을 다하고 있습니다. 이에 회사는 정보통신서비스제공자가 준수하여야 하는 대한민국의 관계 법령 및 개인정보보호, 정보보호에 관한 규정을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 이용자들이 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려드립니다 </p>
                
                <v-card flat class="grey lighten-5 py-2 px-4" width="500">
                    <p>
                        1 개인정보의 수집목적 및 이용목적<br />
                        2 개인정보 수집항목 및 수집방법<br />
                        3 개인정보 수집에 대한 동의<br />
                        4 개인정보의 제공 및 공유<br />
                        5 개인정보 열람, 정정<br />
                        6 개인정보의 보유 및 이용기간<br />
                        7 개인정보의 파기절차 및 방법<br />
                        8 개인정보의 처리위탁<br />
                        9 개인정보 보관 및 보호를 위한 기술적, 관리적 대책<br />
                        10 만 14세 미만 아동의 개인정보<br />
                        11 이용자 및 법정대리인의 권리와 그 행사방법<br />
                        12 개인정보 자동 수집 장치의 설치와 운영 및 거부에 관한 사항<br />
                        13 개인정보보호 책임자<br />
                        14 부칙
                    </p>
                </v-card>
                
                <h3>1. 개인정보의 수집목적 및 이용목적</h3>
                <p>개인정보란 생존하는 개인에 관한 정보로서 해당 이용자 개인을 식별할 수 있는 정보(당해 정보만으로는 특정 개인을 식별할 수 없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함함)를 말합니다 </p>
                
                <h4>개인정보 수집목적</h4>
                <p>회사가 이용자 개인의 정보를 수집하는 목적은 이용자에게 최적의 서비스를 제공하기 위한 것입니다.</p>
                <p>회사는 전자 상거래 및 각종의 콘텐츠를 유/무료로 서비스하고 있습니다. 이때 이용자가 제공한 개인정보를 바탕으로 이용자에게 보다 더 유용한 정보를 선택적으로 제공하는 것이 가능하게 됩니다. 회사는 보다 나은 서비스를 제공하기 위해서 광고를 게재합니다. 이때에도 이용자 개인에 대한 정보를 바탕으로 좀더 유용한 정보로서의 가치가 있는 광고를 선별적으로 전달할 수 있게 됩니다.</p>
                
                <h4>개인정보 이용</h4>
                <p>회원제 서비스 이용에 따른 본인확인, 개인 식별, 불량회원의 부정 이용 방지와 비인가 사용 방지, 가입 의사 확인, 만14세 미만 아동 개인정보 수집 시 법정대리인 정보 및 동의여부 확인, 불만처리, 민원처리, 고지사항 전달, 기타 의사소통을 위한 절차 및 이벤트, 개인맞춤서비스, 연령별 서비스, 인구통계학적 분석 등 정보의 전달을 위한 절차에 이용될 수 있습니다.</p>

                <h3>2. 개인정보 수집항목 및 수집방법</h3>
                <p>회사는 원활한 서비스 제공을 위해 최소한의 개인정보를 수집하고 있습니다.</p>
                <p>이용자는 회원 가입 시(약관동의 필수) 또는 서비스 이용 과정에 따라 최소한의 정보가 수집될 수 있으며 해당 내용은 아래와 같습니다.</p>
                
                <h4>개인정보 수집항목</h4>
                <p>&lt;회원가입 시&gt;</p>
                <p>- 이름, 아이디, 비밀번호, 이메일, 만 14세 미만의 경우 법정대리인 정보(개인식별용 키값)</p>
                <p>&lt;서비스 이용 시&gt;</p>
                <p>- 서비스 이용 기록, 쿠키, IP주소, 기기 정보, 결제내역, 불량 이용 기록, 성별, 성인 인증 값, 이벤트 응모(전화번호, 이름 등), 회원정보 수정 등</p>
                <p>&lt;게시물 신고 시&gt;</p>
                <p>이용자의 문의/답변 내용에 포함된 개인정보, 저작권/개인정보침해 신고 시 사업자등록증 사본/개인 신분증 사본/위임장 정보(대리인 정보) 등</p>
                <p>&lt;이벤트 경품 배송 시&gt;</p>
                <p>- 아이디, 이름, 이메일, 전화번호</p>
                <p>&lt; 경품 배송 시&gt;</p>
                <p>- 주소, 이름</p>
                <p>&lt;유료 서비스 이용&gt;</p>
                <p>- 신용카드 결제 오피캐시 충전 시: 이름, 결제 시 입력한 전화번호/이메일, 결제승인번호 등</p>
                <p>- 휴대전화 결제 시: 이름, 휴대폰번호, 통신사, 결제 시 입력한 이메일, 결제승인번호 등</p>
                <p>&lt;회원 로그인 이용&gt;</p>
                <p>- 아이핀 또는 휴대폰 인증 시: 개인식별용 키값</p>

                <h3>3. 개인정보 수집에 대한 동의</h3>
                <p>회사는 이용자가 회원가입을 할 경우(이하 "회원"이라 함) 개인정보 수집에 대하여 동의를 받고 있습니다.</p>
                <p>회원가입 절차 중 이용약관 및 개인정보처리방침에 개인정보 수집 동의절차에서 해당 이용약관 동의란에 체크하게 되면 개인정보 수집에 대해 동의한 것으로 간주합니다.</p>

                <h3>4. 개인정보의 제공 및 공유</h3>
                <p>회사는 회원의 본인 인증에 필요한 개인정보를 "NICE평가정보㈜"(구 "한국신용평가정보")에 입력과 수집을 일임하고 있으며 해당 개인정보는 일임된 업체에서 관리합니다. 회사는 그 외 회원의 동의가 있거나 법률의 규정에 의한 경우를 제외하고는 어떠한 경우에도 "2. 개인정보 수집항목 및 수집방법"에서 고지한 범위를 넘어서 개인정보를 이용하거나 타인 또는 타기업, 기관에게 제공하지 않습니다. </p>
                <p>회사는 회원의 개인정보를 제3자에게 제공하거나 공유하는 경우에는 사전에 회원에게 개인정보를 제공받거나 공유하게 될 자가 누구이며, 제공 또는 공유되는 개인정보항목이 무엇인지, 왜 그러한 개인정보가 공유되어야 하는지, 그리고 언제까지 어떻게 보호, 관리되는지에 대해 개별적으로 전자우편 또는 회사 서비스 내의 공지사항을 통해 고지하여 동의를 구하는 절차를 거치거나, 회원가입 시 별도로 동의를 구할 수도 있습니다. 회원이 동의하지 않는 경우에는 개인정보를 제공하거나 공유하지 않습니다. 제공 관계에 변화가 있거나 제공 관계가 종결될 때도 같은 절차에 의하여 고지하거나 동의를 구합니다. </p>
                <p>다만, 다음의 경우에는 예외적으로 동의 없이 개인정보를 제공할 수 있습니다. </p>
                <p>- 법령의 규정에 의거하거나, 수사상 목적으로 법령에 정해진 절차와 방법에 따라 관련기관의 요구가 있는 경우</p>
                <p>- 통계작성, 학술연구 또는 시장조사를 위하여 개인을 식별할 수 없는 형태로 제공하는 경우</p>
                
                <h3>5. 개인정보 열람, 정정</h3>
                <p>회원은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 정정을 요청할 수 있습니다. 회원 개인정보의 열람 및 정정은 회사 서비스 로그인 후 '회원정보' 메뉴를 통하여 직접 열람 또는 정정할 수 있습니다. 회사는 개인정보의 오류에 대한 정정을 요청할 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 조치하겠습니다.</p>

                <h3>6. 개인정보의 보유 및 이용기간</h3>
                <p>회사는 회원가입일로부터 회원탈퇴와 같은 개인정보 수집, 이용, 제공에 대한 동의 철회 시까지 회원의 개인정보를 보유 및 이용하게 됩니다. 동의철회는 회사 서비스의 로그인 후 '회원정보' 메뉴 내의 '탈퇴'를 클릭한 다음 정해진 순서에 따라 동의철회를 하면 즉시 회원탈퇴가 완료되며 회원의 개인정보를 파기하는 등 필요한 조치를 취합니다.</p>
                <p>단, 다음의 정보에 대해서는 내부 방침 및 관계법령에 따라 명시한 기간 동안 보관 후 파기합니다.</p>
                <p>1) 불법적 사용자에 대한 관련 기관 수사 협조 및 부정이용 관련하여 최대 3개월간 보관 후 파기합니다.</p>
                <p>- 아이디, 이메일</p>
                <p>- 서비스를 불법적/부정한 형태로 이용한 기록</p>
                <p>2) 관계법령의 규정에 의하여 보관할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보관 후 파기합니다.</p>
                <p>- 이용자 접속 정보(회원 가입 시 IP, 최종 접속 시간): 3개월 (통신비밀보호법)</p>
                <p>- 표시/광고에 관한 기록: 6개월 (전자상거래등에서의 소비자보호에 관한 법률)</p>
                <p>- 계약 또는 청약철회 등에 관한 기록: 5년 (전자상거래등에서의 소비자보호에 관한 법률)</p>
                <p>- 대금결제 및 재화 등의 공급에 관한 기록: 5년 (전자상거래등에서의 소비자보호에 관한 법률)</p>
                <p>- 소비자의 불만 또는 분쟁처리에 관한 기록: 3년 (전자상거래등에서의 소비자보호에 관한 법률)</p>
                <p>3)  경품 배송 주소, 이름은 최대 1년간 보관 후 파기합니다.</p>

                <h3>7. 개인정보의 파기절차 및 방법</h3>
                <p>회사의 개인정보 파기절차 및 방법은 다음과 같습니다. </p>

                <h4>파기절차</h4>
                <p>- 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(개인정보의 보유 및 이용기간 참조) 일정 기간 저장된 후 파기되어집니다. </p>
                <p>- 별도 DB로 옮겨진 개인정보는 법률에 의한 경우가 아니고서는 다른 목적으로 이용되지 않습니다.</p>

                <h4>파기방법</h4>
                <p>- 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.</p>
                <p>- 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.</p>

                <h3>8. 개인정보의 처리위탁</h3>
                <p>회사는 서비스 제공을 위해서 아래와 같이 개인정보 처리업무를 외부 업체에 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.</p>
                
                <h4>[서비스 제공 수탁업체]</h4>
                <table class="in_table">
                    <tr>
                        <th>수탁업체</th>
                        <th>위탁업무</th>
                        <th>개인정보 보유 및 이용기간</th>
                    </tr>
                    <tr>
                        <td>NICE평가정보㈜</td>
                        <td>본인인증</td>
                        <td>해당업체에서 이미 보유하고 있는 개인정보이기 때문에 별도 저장하지 않음</td>
                    </tr>
                    <tr>
                        <td>NHN한국사이버결제</td>
                        <td>휴대폰 결제, 신용카드 결제</td>
                        <td>회원탈퇴 시 혹은 위탁계약 종료 시 까지 </td>
                    </tr>
                </table>

                <h4>[제3자 정보 제공 안내]</h4>
                <p>회사는 이용자들의 개인정보를 수집 당시 동의 받은 범위 내에서만 사용하며, 이용자의 사전 동의 없이는 해당 범위를 초과하여 개인정보를 이용하거나 외부에 공개 또는 제공하지 않습니다.</p>

                <h3>9. 개인정보 보관 및 보호를 위한 기술적, 관리적 대책</h3>
                <p>회원의 개인정보 중 개인식별용 키값과 "2. 개인정보 수집항목 및 수집방법"에서 고지한 정보 이외의 개인정보는 "4. 개인정보의 제공 및 공유"에 고지한 내용에 따라 해당 업체에서 관리합니다. </p>
                <p>회원의 개인정보는 비밀번호에 의해 보호되고 있습니다. 회원 계정의 비밀번호는 오직 본인만이 알 수 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다. 따라서 회원의 비밀번호는 누구에게도 알려주면 안됩니다. 또한 작업을 완료한 후 로그아웃(log-out)을 하고 해당 서비스를 사용한 프로그램(웹브라우저, 앱, 관련 프로그램 등)을 종료하는 것이 바람직합니다. 특히 본인이 사용하는 기기(PC, 스마트폰, 태블릿 등 서비스 사용이 가능한 기기)를 다른 사람과 공유하여 사용하거나 공공장소에서 이용한 경우 개인정보가 다른 사람에게 알려지는 것을 막기 위해서 이와 같은 절차가 더욱 필요하다고 하겠습니다. </p>
                <p>회사는 개인정보 처리 직원을 최소한으로 제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를 강조하고 있으며, 내부적인 점검을 통해 본 정책의 이행사항 및 담당직원의 준수여부를 확인하여 문제가 발견될 경우 바로 시정조치하고 있습니다. 회사의 개인정보보호를 위한 기술적, 관리적 대책은 다음과 같습니다. </p>
                <p>1) 컴퓨터 바이러스에 의한 개인정보의 침해를 방지하기 위하여 백신프로그램을 이용하며 주기적인 업데이트를 통해 새로운 바이러스에 대비하고 있습니다.</p>
                <p>2) 개인정보에 대한 직접적인 접근을 방지하기 위하여 데이터베이스의 보안기능을 이용하여 일부 정보는 열람할 수 없도록 하고 있습니다.</p>
                <p>3) 인터넷망을 통한 해커의 불법적 침입에 대비하여 방화벽과 침입탐지 시스템을 사용하여 보안에 만전을 기하고 있습니다.</p>
                <p>4) 개인정보에 대한 접근권한을 개인정보보호 책임자 등 개인정보관리업무를 수행하는 자, 기타 업무상 개인정보의 처리가 불가피한 자로 제한하며, 그 이외의 인원이 개인정보에 접근하는 것을 허용하지 않습니다.</p>
                <p>5) 개인정보와 일반 데이터를 혼합하여 탑재하지 않으며, 별도의 계정을 통하여 관리하고 있습니다.</p>
                <p>6) 시스템적으로 보안성을 확보하기 위해 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.</p>

                <h3>10. 만 14세 미만 아동의 개인정보</h3>
                <p>회사는 만 14세 미만의 아동의 회원 가입 시 법정대리인의 동의를 확인하기 위하여 필요한 최소한의 정보(법정대리인의 개인정보)를 수집, 이용할 수 있습니다. 회사는 수집된 법정대리인의 개인정보를 해당 법정대리인의 동의 여부를 확인하는 목적 외의 용도로 이용하거나 제3자에게 제공하지 않습니다.</p>
                
                <h3>11. 이용자 및 법정대리인의 권리와 그 행사방법</h3>
                <p>이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며 가입해지를 요청할 수도 있습니다. 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 사이트 초기 페이지의 '개인정보수정'을, 가입해지(동의철회)를 위해서는 개인정보수정 화면 하단의 '회원탈퇴'를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다. 혹은 개인정보보호 책임자에게 서면, 전화 또는 이메일로 연락하면 지체 없이 조치하겠습니다. 이용자가 개인정보의 오류에 대한 정정을 요청할 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3 자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다. 회사는 이용자 혹은 법정대리인의 요청에 의해 해지 또는 삭제된 개인정보는 "6. 동의철회 및 파기절차"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.</p>

                <h3>12. 개인정보 자동 수집 장치의 설치와 운영 및 거부에 관한 사항</h3>
                <p>회사는 이용자들에게 특화된 맞춤서비스를 제공하기 위해서 이용자들의 정보를 저장하고 수시로 불러오는 '쿠키(cookie)'를 사용합니다. 쿠키란 웹사이트를 운영하는데 이용되는 서버가 이용자의 웹브라우저에게 보내는 소량의 텍스트 정보로 이용자들이 사용하는 기기(PC, 스마트폰, 태블릿 등 서비스 사용이 가능한 기기 중 쿠키 설정이 가능한 기기)에 저장되기도 합니다.</p>
                <p>1) 쿠키를 통한 맞춤화 서비스 제공쿠키의 사용 목적</p>
                <p>- 이용자들의 접속 빈도나 방문 시간 등 이용형태와 자취 등을 추적해 이용자들의 취향과 관심분야를 파악하여 이용자에게 최적화된 맞춤형 정보를 제공하기 위함 </p>
                
                <h4>쿠키 설치, 운영 및 거부</h4>
                <p>- 이용자들은 쿠키 사용에 대한 선택권을 가지고 있으며, 웹브라우저의 설정에 따라 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다. </p>
                <p>- 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다. </p>
                <p>단, 쿠키 설치를 거부하였을 경우 일부 서비스 이용에 어려움이 있을 수 있습니다.</p>
                
                <h4>쿠키 설정방법 예</h4>
                <p>1) Internet Explorer - 웹 브라우저 상단의 도구 > 인터넷 옵션 > 개인정보</p>
                <p>2) Chrome - 웹 브라우저 우측의 설정 메뉴 > 화면 하단의 고급 설정 > 개인정보 및 보안 > 사이트 설정 > 쿠키</p>
                <p>2) 구글 애널리틱스를 통한 로그 분석</p>
                <p>- 회사는 구글 애널리틱스(Google Analytics)를 이용하여 로그 분석을 하고 있습니다. 이용자를 식별할 수 없으며 전체적인 로그 분석에만 이용합니다. 이용자는 구글 애널리틱스 수집을 거부할 수 있습니다. ('쿠키 설정 차단' 혹은 '구글 애널리틱스 차단 브라우저 부가 기능' 사용) </p>
                <p>3) 앱 사용 시 서비스 이용 기록 분석</p>
                <p>- 앱 사용 시 원활하고 정상적인 서비스를 위해 앱스토어 또는 광고사 등에서 다음과 같은 정보가 수집될 수 있습니다.</p>
                <p>- 구글 광고 ID, Android ID(안드로이드 OS만 해당), 단말기 정보(모델명, OS버전, 고유식별번호)</p>
                <p>- 광고 식별자에 대해서는 아래와 같은 방법으로 수집을 거부할 수 있습니다.</p>
                <p>- Android인 경우 [설정 > Google > 광고] 또는 [설정 > 일반 > 계정 및 동기화 > Google > 개인정보 및 개인정보보호 > 광고 설정]</p>
                <p>- iOS인 경우 [설정 > 개인정보보호 > 광고]</p>

                <h3>13. 개인정보보호 책임자</h3>
                <p>이용자의 개인정보를 보호하고 개인정보와 관련한 불만을 처리하기 위하여 회사는 개인정보보호 책임자를 두고 있습니다. 이용자의 개인정보와 관련한 문의사항이 있으시면 아래의 개인정보보호 책임자 또는 개인정보보호 담당자에게 연락 바랍니다.</p>
                <p class="grey lighten-4 pl-2">팩 스 : 02-3448-5839</p>
                <p>기타 개인정보침해에 대한 신고나 상담이 필요한 경우 아래 기관에 문의하기 바랍니다.</p>
                <p>- 개인정보침해신고센터 (https://privacy.kisa.or.kr / 국번없이 118)</p>
                <p>- 대검찰청 사이버수사과 (https://www.spo.go.kr / 국번없이 1301)</p>
                <p>- 경찰청 사이버안전국 (https://cyberbureau.police.go.kr / 국번없이 182)</p>

                <h3>14. 부칙</h3>
                <p>이 개인정보처리방침은 2023년 9월 1일부터 적용되며, 본 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 경우 개정 최소 7일전에 회사 서비스 내의 공지사항을 통하여 고지할 것 입니다. 다만, 수집하는 개인정보의 항목, 이용목적의 변경 등과 같이 이용자 권리의 중대한 변경이 발생할 때에는 최소 30일 전에 공지하며, 이 때 변경 내용에 대해 동의하지 않는 회원에 대해 회원 탈퇴를 할 수 있는 안내를 포함합니다.</p>
                <p>- 개인정보처리방침 공고일자 : 2023년 9월 1일</p>
                <p>- 개인정보처리방침 시행일자 : 2023년 9월 1일</p>
            </div>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        
    }),

    mounted(){
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
.policy_banner{
    height:320px;
    filter: brightness(100%); 
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)) ,url('~@/assets/banner/policy/privacy.jpg');
    background-position: 50% 10%;
}
</style>
<style>
.rule_box{
    overflow:auto;
    border:1px solid #e9e9e9;
    text-align:left;
    padding:10px;
}

.rule_box h3{
    color:#2196F3;
}

.in_table{
    width:100%;
    border:1px solid #e0e0e0;
    border-collapse: collapse;
    margin-bottom:20px;
}

.in_table tr{
    height:50px;
}

.in_table tr th:first-child{
    width:130px;
}

.in_table tr th{
    background:#efefef;
    padding:5px;
    border:1px solid #e0e0e0;
}

.in_table tr td{
    width:25%;
    padding:5px;
    border:1px solid #e0e0e0;
}
</style>