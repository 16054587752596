<template>
    <div>
        <!-- 제목 -->
        <v-sheet outlined class="d-flex align-center ma-2 px-4" height="60">
            <font class="font-weight-bold text-h6 grey--text text--darken-2">견적요청 관리</font>
        </v-sheet>

        <!-- 본문 -->
        <v-sheet outlined class="pa-2 ma-2">
            <!-- 목록 -->
            <v-radio-group v-model="selected" hide-details>
                <v-data-table
                    hide-default-header
                    :items="list"
                    :footer-props="{
                        'items-per-page-options': [4, 20, 100, -1],
                        'itemsPerPageText': '페이지당 견적요청 수'
                    }"
                    no-data-text=""
                >
                    <template v-slot:header>
                        <tr>
                            <th style="width:52px;"></th>
                            <th style="width:164px">썸네일</th>
                            <th style="width:200px">건설분야</th>
                            <th style="width:100%">제목</th>
                            <th style="width:140px">필요한 시기</th>
                            <th style="width:120px">마감기한</th>
                            <th style="width:100px">조회수</th>
                            <th style="width:200px">작성일자</th>
                        </tr>
                    </template>
                    <template v-slot:item="{ item }">
                        <tr
                            @click="read(item)"
                            style="cursor:pointer;"
                        >
                            <td
                                @click.stop
                                style="cursor:default;"
                            >
                                <v-radio
                                    class="ml-1"
                                    hide-details
                                    dense
                                    :value="item"
                                    @change="selectedItem = item"
                                ></v-radio>
                            </td>
                            <td class="px-3">
                                <v-sheet
                                    class="mx-auto"
                                    color="transparent"
                                >
                                    <v-img
                                        height="100"
                                        width="140"
                                        :src="'/upload/estimate/thumbnail/'+item.thumbnail"
                                    ></v-img>
                                </v-sheet>
                            </td>
                            <td
                                @click.stop
                                style="cursor:default;"
                            >
                                <v-select
                                    hide-details
                                    outlined
                                    dense
                                    :items="select_list.category"
                                    v-model="item.category"
                                    @change="update_category(item)"
                                ></v-select>
                            </td>
                            <td class="text-truncate">
                                {{item.product_name}}
                            </td>
                            <td class="text-truncate">
                                {{item.pay_date}}
                            </td>
                            <td class="text-truncate">
                                {{item.deadline}}
                            </td>
                            <td class="text-truncate">
                                {{item.view_count}}
                            </td>
                            <td>
                                {{new Date(item.created).toLocaleString()}}
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </v-radio-group>

            <!-- 버튼 -->
            <v-card class="mt-2 pa-2 d-flex justify-end" outlined>
                <v-btn
                    class="px-6 ml-3"
                    color="red"
                    large
                    dark
                    depressed
                    @click="deleteItem()"
                >
                    <v-icon small left>mdi-database-remove-outline</v-icon>
                    삭제하기
                </v-btn>
            </v-card>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        select_list: {
            category: [
                "가스난방",
                "건설인력",
                "건설자재",
                "건설기계",
                "건축시공",
                "계측/측량",
                "구조물해체/비계공사",
                "도장방수",
                "전기/기계/설비",
                "상하수도",
                "설계",
                "시설물유지관리",
                "시험기구",
                "안전용품",
                "인테리어",
                "조경",
                "지반조성/포장",
                "토목시공",
                "품질검사",
                "기타"
            ]
        },
        
        list: [],

        selected: false,
        selectedItem: {},

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true,
        }
    }),

    mounted(){
        // 목록
        this.load()
    },

    methods: {
        // 목록
        load(){
            this.$http.post('/api/estimate_request/select/list', {
                params: {
                    user_id: this.$store.state.asap_user.user_id
                }
            }).then((res) => {
                this.list = res.data
            })
        },

        // 게시글로 이동
        read(item){
            this.$router.push("/estimate?type=read&id="+item.id)
        },

        // 건설분야 수정하기
        update_category(item){
            this.$http.post('/api/estimate_request/update/category', {
                params: {
                    category: item.category,
                    id: item.id
                }
            }).then((res) => {
                if(res.data.affectedRows){
                    alert("건설분야가 변경되었습니다.")
                }
            })
        },

        // 삭제하기
        deleteItem(){
            if(!Object.keys(this.selectedItem).length){
                alert("선택한 견적요청이 없습니다")
            }else{
                if(confirm("정말 해당 견적요청을 삭제하겠습니까?")){
                    this.$http.post('/api/estimate_request/delete', {
                        params: {
                            id: this.selectedItem.id
                        }
                    }).then((res) => {
                        if(res.data.affectedRows){
                            alert("해당 견적요청이 삭제되었습니다.")
                            this.load()
                        }
                    })
                }
            }
        }
    }
}
</script>