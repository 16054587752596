<template>
    <v-sheet
        width="950"
        class="rounded-lg pa-8 pb-6"
        outlined
    >
        <v-sheet class="d-flex mb-3">
            <p class="text-h6 mt-2px">
                <v-icon color="blue" class="mb-1 mr-1">mdi-layers-search-outline</v-icon>
                제휴업체
            </p>
            <v-spacer></v-spacer>

            <!-- 순서 -->
            <v-sheet
                width="180"
                class="mr-2 "
            >
                <v-select
                    class="rounded-lg"
                    dense
                    outlined
                    hide-details
                    v-model="sequence"
                    :items="select_list.sequence"
                >
                    <template v-slot:selection="{ item }">
                        <span class="pl-2">{{item}}</span>
                    </template>
                    <template v-slot:append>
                        <v-icon class="mr-1" color="#424361">mdi-chevron-down</v-icon>
                    </template>
                </v-select>
            </v-sheet>

            <!-- 작성하기 -->
            <v-btn
                width="40"
                height="40"
                class="rounded-lg"
                color="primary"
                dark
                small
                depressed
                @click="write()"
            >
                <v-icon size="20">mdi-pencil</v-icon>
            </v-btn>
        </v-sheet>

        <!-- 목록 -->
        <v-row
            v-if="currentPageList.length"
        >
            <v-col
                v-for="item in currentPageList" :key="item.id"
                cols="4"
                class="mb-2"
                @click="read(item)"
                style="cursor:pointer;"
            >
                <v-img
                    class="rounded-lg"
                    height="180"
                    :src="item.image"
                />
                <p
                    class="ma-1 mb-0 font-weight-medium text-subtitle-1"
                >
                    {{item.title}}
                    <v-icon class="ml-1" color="red" size="20">mdi-alpha-n-box</v-icon>
                </p>
                <p
                    class="mx-1 mb-1 font-weight-medium text-subtitle-2 cyan--text text--darken-2"
                >
                    {{item.category}}
                </p>
                <div
                    class="d-flex justify-space-between mx-1"
                >
                    <div>

                        <p class="my-0 text-caption" style="line-height:17px;">
                            <span>작성자 : {{item.writer}}</span><br/>
                            <span class="mr-2">{{new Date(item.created).toLocaleDateString().slice(0, -1)}}</span>
                            <span>조회수 {{item.view_count}}</span>
                        </p>
                    </div>
                    <div>
                        <v-btn
                            v-ripple="false"
                            height="36"
                            x-small
                            depressed
                            style="background:white; border:1px solid #ccc;"
                            class="mr-2"
                            color="blue--text text--lighten-3"
                            @click.stop="scrap(item)"
                        >
                            <v-icon v-if="item.is_scraped==0" size="18" color="grey lighten-1">mdi-star</v-icon>
                            <v-icon v-if="item.is_scraped!=0" size="18" color="blue">mdi-star</v-icon>
                        </v-btn>
                        <v-btn
                            v-ripple="false"
                            height="36"
                            x-small
                            depressed
                            style="background:white; border:1px solid #ccc;"
                            class="btn-with-hover"
                            color="grey--text text--lighten-1"
                            @click.stop="newTab(item)"
                        >
                            <v-icon size="18">mdi-content-copy</v-icon>    
                        </v-btn>
                    </div>
                </div>
            </v-col>
        </v-row>
        <v-row
            v-if="!currentPageList.length"
        >
            <v-col
                class="py-16 text-center"
            >
                <v-icon
                    size="54"
                    color="grey"
                    class="mb-4"
                >
                    mdi-selection-search
                </v-icon>
                <p
                    class="text-subtitle-1 font-weight-medium grey--text"
                >
                    해당 검색에 해당하는 제휴업체가 없습니다
                </p>
            </v-col>
        </v-row>
        
        <!-- 버튼 & 페이지네이션 -->
        <v-sheet class="mx-2 d-flex align-center">
            <v-spacer></v-spacer>

            <!-- 페이징 -->
            <v-pagination
                class="my-2 elevation-0"
                color="blue"
                v-model="page"
                :length="pageLength"
                :total-visible="10"
            ></v-pagination>
            <v-spacer></v-spacer>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: [
        "list"
    ],

    data: () => ({
        select_list: {
            sequence: [
                "최신순",
                "오래된순",
            ]
        },

        sequence: "최신순",


        // 목록 & 페이징
        currentPageList: [],
        page: 1,
        pageLength:1,
    }),

    watch: {
        // 페이징
        page(){
            this.currentPageList = this.list.slice((this.page-1)*12,(this.page)*12)
        },

        // 순서
        sequence(){
            this.list = this.list.reverse()
        },

        list(){
            this.currentPageList = this.list.slice(0,24)
            this.pageLength = Math.ceil(this.list.length/24)
            this.page = 1
        }
    },

    mounted(){
        this.currentPageList = this.list.slice(0,24)
        this.pageLength = Math.ceil(this.list.length/24)
        this.page = 1
    },

    methods: {
        // HTML 제거
        removeHTML(string){
            return string.replace(/<[^>]*>?/g, '')
        },

        // 게시글로 이동
        read(item){
            this.$router.push("/solution?type=read&id="+item.id)
        },

        // 게시글 작성
        write(){
            if(!this.$store.state.asap_user.is_logined){
                alert("작성하기는 로그인 이후 가능합니다.")
            }else{
                this.$router.push("/solution?type=create")
            }
        },

        // 스크랩하기
        scrap(item){
            if(!this.$store.state.asap_user.is_logined){
                alert("즐겨찾기는 로그인 이후 가능합니다.")
            }else{
                if(item.is_scraped == 0){
                    this.$http.post("/api/solution/insert/scrap", {
                        params: {
                            user_id: this.$store.state.asap_user.user_id,
                            solution_id: item.id
                        }
                    }).then(() => {
                        item.is_scraped = 1
                    })
                }else{
                    this.$http.post("/api/solution/delete/scrap", {
                        params: {
                            user_id: this.$store.state.asap_user.user_id,
                            solution_id: item.id
                        }
                    }).then(() => {
                        item.is_scraped = 0
                    })
                }
            }
        },

        // 새 탭 열기
        newTab(item){
            window.open("/solution?type=read&id="+item.id, '_blank')
        }
    }
}
</script>
<style>
.v-btn::before {
    background-color: transparent;
}

.btn-with-hover:hover .v-icon {
    color: #2196F3;
}

.custom_list tr:hover td:hover {
  background-color: #f7f7f7;
}
</style>