<template>
    <!-- 견적 상세보기 -->
    <v-sheet
        width="600"
        class="rounded-xl pa-10 py-9 mx-auto"
        outlined
    >
        <p class="text-h6 mb-4">
            <v-icon color="blue" class="mb-1 mr-1">mdi-layers-edit</v-icon>
            견적 상세보기
        </p>

        <!-- 공급자 -->
        <v-sheet
            outlined
            class="px-4 py-3 rounded-lg mb-4"
        >
            <p
                class="text-body-1 font-weight-medium mb-3"
            >
                <v-icon small color="primary" class="pb-1 mr-1">mdi-account-arrow-right-outline</v-icon>
                공급자
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:64px">업체명:</span>{{provider_company}}
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:64px">주소:</span>{{provider_address}}
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:64px">연락처:</span>{{provider_phone}}
            </p>
        </v-sheet>

        <!-- 견적내용 -->
        <v-sheet
            outlined
            class="px-4 py-3 rounded-lg mb-4"
        >
            <p
                class="text-body-1 font-weight-medium mb-3"
            >
                <v-icon small color="primary" class="pb-1 mr-1">mdi-post-outline</v-icon>
                견적내용
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:64px">품명:</span>{{estimate_product_name}}
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:64px">수량:</span>{{estimate_amount}}
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:64px">단가:</span>{{estimate_price}}
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:64px">합계:</span>{{estimate_total}}
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:64px">파일:</span>
            </p>

            <!-- 첨부파일 -->
            <v-sheet
                v-if="attachment"
                class="pa-3"
            >
                <v-btn
                    depressed
                    class="py-6 rounded-10"
                    @click="download(attachment)"
                >
                    <v-icon size="20" class="mt-1 mr-2" color="primary">mdi-file</v-icon>
                    {{attachment}}
                </v-btn>
            </v-sheet>

        </v-sheet>

        <!-- 견적내용 -->
        <v-sheet
            outlined
            class="px-4 py-3 rounded-lg"
        >
            <p
                class="text-body-1 font-weight-medium mb-3"
            >
                <v-icon small color="primary" class="pb-1 mr-1">mdi-check-circle-outline</v-icon>
                조건
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:120px">납기:</span>{{condition_pay_date}}
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:120px">견적유효기간:</span>{{condition_validity}}
            </p>
            <p class="ml-2 mb-1">
                <span style="display:inline-block; width:120px">결제조건:</span>{{condition_option}}
            </p>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    props: ["id"],
    
    data: () => ({
        provider_company: "",
        provider_address: "",
        provider_phone: "",
        estimate_product_name: "",
        estimate_amount: "",
        estimate_price: "",
        estimate_total: "",
        condition_pay_date: "",
        condition_validity: "",
        condition_option: "",
        attachment: ""
    }),

    mounted(){
        this.load()
    },

    methods: {
        load(){
            this.$http.post("/api/estimate/select/specific", {
                params: {
                    id: this.id
                }
            }).then((res) => {
                console.log(res)
                this.provider_company = res.data[0].provider_company
                this.provider_address = res.data[0].provider_address
                this.provider_phone = res.data[0].provider_phone
                this.estimate_product_name = res.data[0].estimate_product_name
                this.estimate_amount = res.data[0].estimate_amount
                this.estimate_price = res.data[0].estimate_price
                this.estimate_total = res.data[0].estimate_total
                this.condition_pay_date = res.data[0].condition_pay_date
                this.condition_validity = res.data[0].condition_validity
                this.condition_option = res.data[0].condition_option
                this.attachment = res.data[0].attachment
            })
        },

        download(file) {
            this.$http.post("/api/file/download", {
                params: {
                    file: file
                }
            },
            {
                responseType: 'blob' // 응답 타입을 'blob'으로 설정
            }).then((res) => {
                console.log(res)
                const url = window.URL.createObjectURL(new Blob([res.data])) // 블롭 객체 생성
                const link = document.createElement('a') // 링크 엘리먼트 생성
                link.href = url // 링크에 다운로드 URL 설정
                link.setAttribute('download', file) // 파일 이름 설정
                document.body.appendChild(link) // 링크를 body에 추가
                link.click() // 클릭 이벤트 발생
                document.body.removeChild(link) // 링크 제거
                window.URL.revokeObjectURL(url) // 사용한 URL 해제
            })
        }
    }
}
</script>
<style scoped>
::v-deep .v-text-field input {
    margin-left:2px;
}
</style>