<template>
    <v-sheet
        color="#f9f9f9"
    >
        <v-sheet
            :width="!$vuetify.breakpoint.mobile? 1200:'100%'"
            :class="!$vuetify.breakpoint.mobile? 'pt-12 pb-16 px-2 d-flex justify-space-between mx-auto':''"
            color="transparent"
        >
            <!-- 사이드 메뉴 -->
            <SideMenu />

            <!-- 본문 -->
            <v-sheet
                :width="!$vuetify.breakpoint.mobile? 950:'100%'"
                :class="!$vuetify.breakpoint.mobile? 'pa-2 pb-10':'pa-4 pb-10'"
                class="rounded-lg pa-6"
                outlined
            >
                <p
                    class="text-h5 font-weight-bold"
                    style="letter-spacing:1px !important;"
                >
                    문의사항
                </p>
                <v-divider></v-divider>

                <v-sheet 
                    color="white" 
                    class="mx-auto"
                    style="position:relative;"
                >
                    <div class="rule_box pt-16 pb-14">
                        <v-sheet
                            max-width="380"
                            outlined
                            class="py-3 px-10 rounded-30 mx-auto mb-2"
                            style="cursor:pointer;"
                            @click="$copyText('contact@connara.co.kr'); snackbar = true"
                        >
                            <p
                                class="my-2 text-subtitle-1 font-weight-bold text-center"
                            >
                                contact@connara.co.kr
                            </p>
                        </v-sheet>

                        <p
                            class="text-caption text-center primary--text mb-12"
                        >
                            {{snackbar? '이메일이 복사되었습니다':'이메일을 클릭하시면 복사됩니다'}}
                        </p>

                        <p
                            class="text-center mb-2 font-weight-medium"
                        >
                            문의하실 내용은 위의 이메일로 보내주세요
                        </p>
                        <p
                            class="text-center text-body-2 primary--text font-weight-medium"
                        >
                            감사합니다
                        </p>
                    </div>
                </v-sheet>

                <!-- 스낵바 -->
                <v-snackbar
                    min-width="0"
                    min-height="0"
                    content-class="pa-0"
                    top
                    light
                    v-model="snackbar"
                    :timeout="2000"
                    :action="null"
                >
                    <v-sheet
                        class="primary py-3 px-16 white--text text-center"
                    >
                        이메일이 복사되었습니다
                    </v-sheet>
                </v-snackbar>
            </v-sheet>
        </v-sheet>
    </v-sheet>
</template>
<script>
import SideMenu from './components/SideMenu'

export default {
    components: {
        SideMenu
    },

    data: () => ({
        snackbar: false
    }),
}
</script>
<style scoped>
::v-deep .v-snack__action {
    display: none;
}
</style>