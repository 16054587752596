<template>
    <v-sheet
        class="pt-16"
        style="padding-bottom:240px;"
        color="#f9f9f9"
    >
        <v-sheet 
            color="white" 
            class="mx-auto"
            width="1200"
            style="position:relative"
        >
            <v-sheet
                width="540"
                height="28"
                class="blue"
                style="position:absolute; top:-14px; left:340px; box-shadow:0px 1px 10px #ccc;"
            ></v-sheet>
            <div class="rule_box pt-10 pa-16">
                <!-- 제목 -->
                <h2
                    class="text-center mb-10 mt-2"
                    style="letter-spacing:2px"
                >
                    이용약관
                </h2>

                <h2>제1장 총칙</h2>
                <h3>제 1 조 (목적)</h3>
                <p>
                    본 약관은 회사명의 ‘건설나라’가 건설나라 홈페이지(이하 "홈페이지"라 한다)를 통해 제공하는<br/>
                    모든 인터넷 정보 서비스(이하 "서비스"라 한다)를 이용함에 있어서 건설나라와 정보 이용자 간의 권리·의무 및 책임사항을 규정함으로써 고객의 권익을 보호함을 목적으로 합니다.
                </p>

                <h3>제 2 조 (용어의 정의)</h3>
                <ol class="pl-4">
                    <li>이용자란 홈페이지에 접속하여 이 약관에 따라 건설나라가 제공하는 서비스를 받는 회원 및 비회원을 말합니다.</li>
                    <li>회원이라 함은 홈페이지에 개인이나 회사의 정보를 제공하여 회원 등록을 하거나, 건설나라에게 아이디와 비밀번호를 제공받은 개인이나 회사로서,<br/>
                        건설나라의 정보를 지속적으로 이용할 수 있는 자나 회사를 말합니다.</li>
                    <li>비회원이라 함은 회원에 가입하지 않고 건설나라이 제공하는 서비스를 이용하는 자를 말합니다.</li>
                </ol>

                <h3>제3조 (약관의 효력과 개정)</h3>
                <ol class="pl-4">
                    <li>이 약관은 이용자에게 공시함으로써 효력이 발생됩니다.</li>
                    <li>건설나라는 이 약관의 내용을 이용자가 알 수 있도록 건설나라 홈페이지에 게시합니다.</li>
                    <li>약관 변경 시 고지 기간<br/>
                        약관의 변경 시 개정 약관 적용일 전 최소 7일부터 회원에게 이를 고지해야 하고, 만일 약관의 변경이 소비자에게 불리한 경우에는<br/>
                        개정 약관 적용일 전 최소 30일부터 회원에게 이를 고지합니다.
                    </li>
                </ol>

                <h2>제2장 회원 가입</h2>

                <h3>제4조 (회원 가입)</h3>
                <ol class="pl-4">
                    <li>회원으로 등록하여 서비스를 이용하고자 하는 자는 회사에서 요청하는 개인 신상 정보를 제공하여야 합니다.</li>
                    <li>이용자는 건설나라가 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사 표시를 함으로서 회원 가입을 신청합니다.</li>
                    <li>건설나라는 제2항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각 호에 해당하지 않는 한 회원으로 등록합니다.<br/>
                        1) 등록 내용에 허위, 기재 누락, 오기가 있는 경우<br/>
                        2) 기타 회원으로 등록하는 것이 건설나라의 기술상 현저히 지장이 있다고 판단되는 경우<br/>
                        3) 만 14세 미만인 자
                    </li>
                    <li>회원가입의 성립시기는 건설나라의 승낙이 회원에게 도달한 시점으로 합니다.</li>
                    <li>회원은 등록사항에 변경이 있는 경우, 즉시 전자 우편 및 기타 방법으로 건설나라에 그 변경 사항을 알려야 합니다.</li>
                </ol>

                <h3>제5조 (회원 탈퇴 및 자격 상실 등)</h3>
                <ol class="pl-4">
                    <li>회원은 언제든지 건설나라에 탈퇴를 요청할 수 있으며 건설나라은 즉시 회원 탈퇴를 처리합니다.</li>
                    <li>회원이 다음 각 호의 사유에 해당하는 경우, 건설나라는 회원 자격을 제한 및 정지시킬 수 있습니다.<br/>
                        1) 가입 신청 시에 허위 내용을 등록한 경우<br/>
                        2) 다른 사람의 건설나라 홈페이지 이용을 방해하거나 그 정보를 도용하는 등 정보 이용 규칙을 위협하는 경우<br/>
                        3) 불량이용회원의 강제 탈회 조치(강제 등록말소 등)시 일정한 소명기간(7일이상)을 정하여 회원에게 소명기간을 부여합니다.
                    </li>
                    <li>회원가입의 성립시기는 건설나라의 승낙이 회원에게 도달한 시점으로 합니다.</li>
                    <li>회원은 등록사항에 변경이 있는 경우, 즉시 전자 우편 및 기타 방법으로 건설나라에 그 변경 사항을 알려야 합니다.</li>
                </ol>

                <h3>제6조 (회원에 대한 통지)</h3>
                <ol class="pl-4">
                    <li>건설나라는 회원이 건설나라에 제출한 전자우편 주소로 통지를 할 수 있습니다.</li>
                    <li>건설나라는 불특정다수 회원에 대한 통지의 경우 1주일 이상 홈페이지에 게시함으로서 개별 통지에 갈음할 수 있습니다.</li>
                </ol>

                <h2>제3장 서비스 제공 및 이용</h2>

                <h3>제7조 (서비스 제공)</h3>
                <ol class="pl-4">
                    <li>건설나라의 홈페이지에서 제공하는 서비스의 내용은 무료입니다.</li>
                    <li>건설나라는 홈페이지의 내용의 서비스 변경이 필요하다고 판단이 될 경우, 변경될 내용을 추가 또는 변경할 수 있습니다.</li>
                    <li>서비스 내용이 변경될 경우, 건설나라는 이용자에게 그 내용을 사전에 공지 사항으로 게시합니다.</li>
                </ol>

                <h3>제8조 (서비스 이용 및 제한)</h3>
                <ol class="pl-4">
                    <li>건설나라의 업무상 또는 기술상 특별한 지장이 없는 한, 중단 없이 서비스를 제공함을 원칙으로 합니다.</li>
                    <li>정보통신시설의 보수 점검ㆍ교체 및 고장, 통신의 두절 등의 사유가 발생하거나, 시스템 장애, 서비스 이용의 폭주 등 불가항력으로 인하여<br/>
                        서비스 이용에 지장이 있는 경우 서비스 이용의 전부 또는 일부에 대하여 제한할 수 있습니다.
                    </li>
                </ol>

                <h3>제9조 (서비스 이용 요금)</h3>
                <ol class="pl-4">
                    <li>건설나라가 제공하는 서비스는 유료로 이용하는 것이 원칙입니다.<br/>
                        다만 일부 서비스 중 무료로 표시 된 서비스는 무료로 이용 하실 수 있습니다.<br/>
                        일부 서비스에대한 유무료 전환은 사전에 홈페이지를 통해 공지사항으로 통보합니다.
                    </li>
                </ol>

                <h2>제4장 건설나라과 이용자의 의무사항</h2>

                <h3>제10조 (건설나라의 의무)</h3>
                <ol class="pl-4">
                    <li>건설나라는 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 서비스를 제공하는 데 최선을 다해야 합니다.</li>
                    <li>건설나라는 회원의 정보가 분실ㆍ도난ㆍ변조ㆍ누출 또는 훼손되지 않도록 보안 및 안정성 확보에 필요한 조치를 취해야 합니다.</li>
                    <li>건설나라는 이용자가 원하지 않는 영리 목적의 광고성 전자 우편을 발송하지 않습니다.</li>
                </ol>

                <h3>제11조 (회원의 의무)</h3>
                <ol class="pl-4">
                    <li>ID와 비밀번호에 대한 관리 책임은 회원에게 있습니다.</li>
                    <li>회원은 자신의 ID 및 비밀 번호를 제3자에게 이용하게 해서는 안됩니다.</li>
                    <li>회원은 자신의 ID 및 비밀 번호를 도난 당한 것을 인지한 경우, 건설나라에 즉시 통보하고 건설나라의 안내에 따라야 합니다.</li>
                </ol>

                <h2>제5장 기타</h2>

                <h3>제12조 (약관 외 준칙)</h3>
                <p>이 약관에 명시되지 않은 사항은 관련법과 표준 약관의 규정에 의합니다.</p>

                <h3>제13조 (분쟁해결)</h3>
                <ol class="pl-4">
                    <li>건설나라는 이용자로부터 제출되는 불만사항 및 의견을 우선적으로 처리합니다. 단, 처리가 곤란한 경우에는 이용자에게 그 사유와 처리 일정을 지체없이 통보합니다.</li>
                    <li>건설나라와 이용자간에 발생한 분쟁은 관련법에 의한 조정에 따를 수 있습니다.</li>
                </ol>

                <h3>제14조 (저작권의 귀속 및 이용 제한)</h3>
                <ol class="pl-4">
                    <li>건설나라와 이용자간에 발생한 분재에 관한 소송은 서울중앙지방법원에 제기할 수 있습니다.</li>
                    <li>이용자는 홈페이지를 이용함으로써 얻은 정보를 건설나라의 사전 승낙 없이 복제, 송신, 출판, 배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안됩니다.</li>
                    <li>건설나라와 이용자간에 발생한 분재에 관한 소송은 서울 지방법원에 제기할 수 있습니다.</li>
                    <li>건설나라와 이용자간에 제기된 소송에는 대한민국법을 적용합니다.</li>
                </ol>

                <br />
                <h2>부 칙</h2>
                <p>(공고 및 시행일) 이 약관은 2023년 9월 1일부터 공고하며 2023년 9월 1일부터 적용됩니다.</p>
                
                <p>시행일자 : 2023년 9월 1일</p>
            </div>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        
    }),

    mounted(){
        window.scrollTo(0, 0)
    }
}
</script>
<style scoped>
.policy_banner{
    height:320px;
    filter: brightness(100%); 
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)) ,url('~@/assets/banner/policy/rule.jpg');
    background-position: 64% 23%;
}
</style>
<style scoped>
.rule_box{
    overflow:auto;
    border:1px solid #e9e9e9;
    text-align:left;
    padding:10px;
}

.rule_box h2{
    color:#37474F;
    margin-top:28px;
    margin-bottom:12px;
}

.rule_box h3{
    color:#2196F3;
    margin-top:12px;
    margin-bottom:4px;
}

.in_table{
    width:640px;
    border:1px solid #e0e0e0;
    border-collapse: collapse;
    margin-bottom:20px;
    font-size:14px;
}

.in_table tr{
    height:38px;
}

.in_table tr th:first-child{
    width:130px;
}

.in_table tr th{
    background:#efefef;
    padding:4px 8px;
    border:1px solid #e0e0e0;
}

.in_table tr td{
    padding:4px 8px;
    border:1px solid #e0e0e0;
}
</style>