<template>
    <v-sheet
        :width="!$vuetify.breakpoint.mobile? 230:''"
        outlined
        class="rounded-lg pa-2"
    >
        <v-sheet
            :class="!$vuetify.breakpoint.mobile? 'pt-7':'pt-4'"
            :height="!$vuetify.breakpoint.mobile? '112':'92'"
        >
            <p
                class="ml-2 mb-0 grey--text"
                :class="!$vuetify.breakpoint.mobile? 'text-subtitle-1':'text-subtitle-2'"
            >
                Service Center
            </p>
            <p
                class="ml-2 mb-2 font-weight-bold primary--text"
                :class="!$vuetify.breakpoint.mobile? 'text-h5':'text-h6'"
            >
                고객센터
            </p>
            <v-divider></v-divider>
        </v-sheet>
        <v-list
            class="mt-0 pt-0"
            :dense="$vuetify.breakpoint.mobile"
        >
            <v-list-item
                v-for="(item, index) in menu"
                :key="index"
                class="pl-4"
                :class="!$vuetify.breakpoint.mobile? 'text-h6':''"
                style="cursor:pointer; border-bottom:1px solid #ddd;"
                :style="item.url == $route.path? 'background:#2196f3;':''"
                @click="link(item.url)"
            >
                <v-list-item-title>
                    <font :color="item.url == $route.path? 'white':'black'">
                        {{item.title}}
                    </font>
                </v-list-item-title>
                <v-list-item-icon>
                    <v-icon :color="item.url == $route.path? 'white':'primary'">mdi-chevron-right</v-icon>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        menu: [
            {
                title: "공지사항",
                url: "/cs/notice"
            },
            {
                title: "FAQ",
                url: "/cs/faq"
            },
            {
                title: "문의사항",
                url: "/cs/suggestion"
            },
        ]
    }),

    methods: {
        link(url){
            this.$router.push(url)
        }
    }
}
</script>
<style scoped>
.striped {
    background: repeating-linear-gradient(
        45deg,
        white,
        white 2px,
        #eeeeee 2px,
        #eeeeee 4px
    );
}
</style>