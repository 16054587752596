<template>
    <div style="border-bottom:2px solid #90CAF9">
        <v-sheet
            height="90"
            width="1200"
            class="d-flex align-center mx-auto"
        >
            <!-- 로고 -->
            <v-sheet 
                width="250"
                class="d-flex justify-center mt-1"
            >
                <v-img
                    height="44"
                    width="66"
                    style="cursor:pointer"
                    contain
                    src="@/assets/logo/default.png"
                    @click="$router.push('/')"
                ></v-img>
            </v-sheet>
            <v-spacer></v-spacer>

            <!-- 메뉴 -->
            <div>
                <v-btn
                    v-ripple="false"
                    class="text-subtitle-1 font-weight-medium mr-6"
                    text
                    large
                    active-class="primary--text"
                    exact
                    to="/"
                >
                    견적요청
                </v-btn>
                <!-- <v-btn
                    v-ripple="false"
                    class="text-subtitle-1 font-weight-medium mr-6"
                    text
                    large
                    active-class="primary--text"
                    exact
                    to="/estimate?type=list"
                >
                    견적요청 목록
                </v-btn> -->
                <v-btn
                    v-ripple="false"
                    class="text-subtitle-1 font-weight-medium mr-7"
                    text
                    large
                    active-class="primary--text"
                    exact
                    to="/solution?type=list"
                >
                    업체 목록
                </v-btn>

                <!-- 고객센터 -->
                <v-menu
                    transition="scroll-y-transition"
                    open-delay="200"
                    offset-y
                    open-on-hover
                    nudge-bottom="11"
                    nudge-left="10"
                    content-class="elevation-0"
                    close-delay="200"
                >
                    <template v-slot:activator="{ on: menu }">
                        <v-hover v-slot="{ hover }">
                            <v-btn
                                :style="hover? 'color:#2196f3':''"
                                v-ripple="false"
                                class="text-subtitle-1 font-weight-medium"
                                text
                                large
                                active-class="primary--text"
                                @click="$push('/cs/notice')"
                                v-on="{ ...menu }"
                            >
                                고객센터
                            </v-btn>
                        </v-hover>
                    </template>
                    <v-list width="120" class="text-center rounded-10" color="primary">
                        <v-list-item dense exact>
                            <v-list-item-title
                                class="custom-list white--text font-weight-regular"
                                style="font-size:15px; cursor:pointer;"
                                @click="$push('/cs/notice')"
                            >
                                공지사항
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item dense exact>
                            <v-list-item-title
                                class="custom-list white--text font-weight-regular"
                                style="font-size:15px; cursor:pointer;"
                                @click="$push('/cs/faq')"
                            >
                                FAQ
                            </v-list-item-title>
                        </v-list-item>
                        <v-list-item dense exact>
                            <v-list-item-title
                                class="custom-list white--text font-weight-regular"
                                style="font-size:15px; cursor:pointer;"
                                @click="$push('/cs/suggestion')"
                            >
                                문의사항
                            </v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </div>

            <!-- 비로그인 상태 -->
            <div
                v-if="!$store.state.asap_user.is_logined"
                style="width:280px;"
                class="d-flex justify-end"
            >
                <v-btn
                    class="font-weight-medium mr-4 px-6 rounded-xl"
                    depressed
                    color="blue"
                    dark
                    to="/auth/join/agree"
                >
                    회원가입
                </v-btn>
                <v-btn
                    v-ripple="false"
                    class="font-weight-bold mr-4"
                    text
                    to="/auth/login"
                >
                    로그인
                </v-btn>
            </div>

            <!-- 로그인 상태 -->
            <div
                v-if="$store.state.asap_user.is_logined"
                style="width:280px;"
                class="d-flex align-center justify-end"
            >
                <!-- 로그인 상태 (메세지) -->
                <div
                    class="mr-8"
                >
                    <!-- 메세지 내역이 있을때 -->
                    <v-badge
                        v-if="message_unwatched!=0"
                        color="error"
                        :content="message_unwatched"
                        offset-x="28"
                        offset-y="10"
                    >
                        <v-btn
                            width="24"
                            height="24"
                            fab
                            text
                            depressed
                            class="custom-btn"
                            v-ripple="false"
                            to="/message"
                        >
                            <v-icon
                                class="mr-10"
                                color="blue"
                                style="cursor:pointer;"
                            >
                                mdi-email-outline
                            </v-icon>
                        </v-btn>
                    </v-badge>

                    <!-- 메세지 내역이 없을때 -->
                    <v-btn
                        v-if="message_unwatched==0"
                        width="24"
                        height="24"
                        fab
                        text
                        depressed
                        class="custom-btn"
                        v-ripple="false"
                        to="/message"
                    >
                        <v-icon
                            class="mr-10"
                            color="grey"
                            style="cursor:pointer;"
                        >
                            mdi-email-outline
                        </v-icon>
                    </v-btn>
                </div>

                <!-- 로그인 상태 (프로필) -->
                <div
                    class="d-flex mr-12"
                >
                    <v-menu
                        transition="scroll-y-transition"
                        open-delay="200"
                        offset-y
                        open-on-hover
                        content-class="elevation-0"
                        nudge-right="-56"
                        nudge-bottom="11"
                        close-delay="200"
                    >
                        <!-- 프로필 이미지 -->

                        <template v-slot:activator="{ on: menu }">
                            <v-avatar
                                class="ml-2 mr-2"
                                color="rgb(219, 219, 219)"
                                size="40"
                                v-on="{ ...menu }"
                                style="cursor:pointer;"
                            >
                                <v-img
                                    :src="require('@/assets/profile/'+$store.state.asap_user.profile_image)"
                                ></v-img>
                            </v-avatar>
                        </template>

                        <!-- 목록 -->
                        <v-list width="150" class="text-center rounded-lg pt-2 pb-3" color="primary">
                            <!-- 유저아이디 -->
                            <v-subheader v-if="$store.state.asap_user.is_logined" class="mb-1">
                                <v-sheet class="mx-auto text-center" color="transparent">
                                    <p class="text-body-1 mb-0 font-weight-bold white--text">{{$store.state.asap_user.user_id}}</p>
                                </v-sheet>
                            </v-subheader>

                            <!-- 관리자 페이지 -->
                            <v-list-item dense exact v-if="$store.state.asap_user.user_id=='root'" :to="$store.state.asap_user.is_logined? '/admin/dashboard':'admin/login'">
                                <v-list-item-title class="custom-list white--text font-weight-regular" style="font-size:15px; cursor:pointer;">
                                    관리자페이지
                                </v-list-item-title>
                            </v-list-item>

                            <!-- 마이페이지 -->
                            <v-list-item dense exact to="/mypage/home">
                                <v-list-item-title class="custom-list white--text font-weight-regular" style="font-size:15px; cursor:pointer;">
                                    마이페이지
                                </v-list-item-title>
                            </v-list-item>

                            <!-- 로그아웃 -->
                            <v-list-item dense exact to="/auth/logout">
                                <v-list-item-title class="custom-list white--text font-weight-regular" style="font-size:15px; cursor:pointer;">
                                    로그아웃
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>
                </div>
            </div>
        </v-sheet>
    </div>
</template>
<script>
export default {
    data: () => ({
        message_unwatched: 0
    })
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}

.v-btn:hover {
    color: #2196f3 !important;
}
</style>