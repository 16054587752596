<template>
    <v-sheet
        width="1200"
        class="mx-auto pt-9 px-2"
        color="transparent"
    >
        <!-- 문구 -->
        <v-sheet color="transparent" width="400" class="mx-auto">
            <div class="d-flex mx-auto">
                <v-img
                    height="60"
                    contain
                    src="@/assets/logo/default.png"
                ></v-img>
            </div>
            <p class="text-center text-subtitle-2 font-weight-medium mt-2 mb-0 grey--text text--darken-2">
                최고의 건설 파트너를 찾고 계신가요?
            </p>
            <p class="text-center text-caption grey--text text--darken-1">
                <span class="primary--text text--darken-3 font-weight-medium">건설나라</span>에서 간편하게 파트너를 비교하고 선택하세요!
            </p>
        </v-sheet>

        <!-- 견적 요청 -->
        <v-sheet
            width="600"
            class="rounded-xl mt-6 pa-10 pb-7 mx-auto"
            outlined
            @drop.prevent="onDrop" 
            @dragover="checkDrop($event)" 
            @paste="checkPaste($event)"
        >
            <p class="text-h6 mb-3">
                <v-icon color="blue" class="mb-1 mr-1">mdi-layers-edit</v-icon>
                <span class="primary--text">무료</span> 견적요청
            </p>
            <v-textarea
                class="rounded-lg mb-4"
                rows="2"
                outlined
                hide-details
                label="제목"
                v-model="estimate.product_name"
                @change="checkLogin()"
            ></v-textarea>
            <!-- <v-text-field
                dense
                class="rounded-lg mb-4"
                outlined
                hide-details
                label="수량"
                type="number"
                placeholder="자유롭게 기술하세요"
                v-model="estimate.amount"
                @change="checkLogin()"
            ></v-text-field> -->
            <v-select
                dense
                class="rounded-lg mb-4"
                outlined
                hide-details
                label="건설분야"
                v-model="estimate.category"
                :items="select_list.category"
                @change="checkLogin()"
            ></v-select>
            <v-text-field
                dense
                class="rounded-lg mb-4"
                outlined
                hide-details
                label="필요한 시기"
                placeholder="자유롭게 기술하세요"
                v-model="estimate.pay_date"
                @change="checkLogin()"
            ></v-text-field>
            <v-text-field
                dense
                class="rounded-lg mb-4"
                outlined
                hide-details
                label="마감기한"
                placeholder="자유롭게 기술하세요"
                v-model="estimate.deadline"
                @change="checkLogin()"
            ></v-text-field>
            <v-textarea
                class="rounded-lg mb-4"
                rows="3"
                outlined
                hide-details
                label="내용설명"
                placeholder="제품 상품 서비스 설명 또는 이미지 드래그, 엑셀 등등 자유롭게"
                v-model="estimate.memo"
                @change="checkLogin()"
            ></v-textarea>
            <v-file-input
                outlined
                show-size
                hide-details
                prepend-icon
                color="blue"
                class="rounded-lg mb-4"
                label="관련 이미지 첨부"
                v-model="uploadImage"
            >
                <template v-slot:prepend-inner>
                    <v-icon class="mr-1" color="blue">mdi-image-outline</v-icon>
                </template>
            </v-file-input>
            <v-file-input
                outlined
                show-size
                hide-details
                prepend-icon
                color="blue"
                class="rounded-lg"
                label="파일 첨부"
                v-model="uploadFile"
            >
                <template v-slot:prepend-inner>
                    <v-icon class="mr-1" color="blue">mdi-file-chart-outline</v-icon>
                </template>
            </v-file-input>

            <v-sheet class="d-flex pt-3">
                <v-checkbox
                    class="mb-0 mt-3px ml-6px pa-0"
                    color="primary"
                    v-model="privacy"
                    :error-messages="privacy_Errors"
                    required
                    @change="$v.privacy.$touch()"
                    @blur="$v.privacy.$touch()"
                >
                    <template v-slot:label>
                        <p class="mb-0 pb-2px text-body-2 font-weight-medium">개인정보 수집ㆍ이용 동의</p>
                    </template>
                </v-checkbox>
                <v-spacer></v-spacer>
                <router-link to="/policy/privacy?type=read" style="cursor:pointer; text-decoration:none !important;">
                    <p class="text-subtitle-2 font-weight-bold mt-3px mr-3 primary--text">
                        약관보기
                        <v-icon size="20" class="ml-6px mb-2px" color="primary">
                            mdi-open-in-new
                        </v-icon>
                    </p>
                </router-link>
            </v-sheet>

            <!-- 버튼 -->
            <div class="mt-6 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="110"
                    height="42"
                    dark
                    depressed
                    color="blue"
                    class="font-weight-bold rounded-lg mr-4"
                    @click="submit()"
                >
                    확인
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="110"
                    height="42"
                    depressed
                    color="grey"
                    dark
                    class="font-weight-bold rounded-lg"
                    @click="close()"
                >
                    취소
                </v-btn>
            </div>
        </v-sheet>
    </v-sheet>
</template>
<script>
import { validationMixin } from 'vuelidate'

export default {
    mixins: [validationMixin],

    validations: {
        privacy: {
            checked (val) {
                return val
            }
        }
    },


    data: () => ({
        select_list: {
            category: [
                "가스난방",
                "건설인력",
                "건설자재",
                "건설기계",
                "건축시공",
                "계측/측량",
                "구조물해체/비계공사",
                "도장방수",
                "전기/기계/설비",
                "상하수도",
                "설계",
                "시설물유지관리",
                "시험기구",
                "안전용품",
                "인테리어",
                "조경",
                "지반조성/포장",
                "토목시공",
                "품질검사",
                "기타"
            ],

        },

        uploadImage: {},
        uploadFile: {},

        estimate: {
            category: "",
            product_name: "",
            amount: 0,
            pay_date: "",
            deadline: "",
            memo: ""
        },

        privacy: false
    }),

    computed: {
        privacy_Errors () {
            const errors = []
            if (!this.$v.privacy.$dirty) return errors
            !this.$v.privacy.checked && errors.push('개인정보 수집ㆍ이용에 동의해주세요')
            return errors
        }
    },

    methods: {
        // Copy & Paste (파일 있을시 파일 업로드 Call)
        checkPaste(e){
            console.log(e)
        },

        // Drag & Drop (기본 이벤트 차단)
        checkDrop(e) {
            e.preventDefault()
        },

        // Drag & Drop (파일 업로드 Call)
        async onDrop(e){
            console.log(e)
            let file = e.dataTransfer.files[0]
            console.log(file)
            if(this.isImageFile(file)){
                this.uploadImage = file
            }else{
                this.uploadFile = file
            }
        },

        checkLogin(){
            if(!this.$store.state.asap_user.is_logined){
                alert("견적요청은 로그인 이후 가능합니다")
            }
        },

        isImageFile(file) {
            // 파일 확장자 확인
            const extension = file.name.split(".").pop().toLowerCase()
            if (extension === "jpg" || extension === "png" || extension === "gif") {
                return true
            }

            // 파일 유형(MIME) 확인
            const mimeType = file.type
            if (mimeType.startsWith("image/")) {
                return true
            }
            return false
        },

        // 제출
        submit: _.debounce(function() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                return
            }

            if(!this.$store.state.asap_user.is_logined){
                alert("견적요청은 로그인 이후 가능합니다")
                return
            }

            // 이미지 O & 파일첨부 O 
            if(this.uploadImage.name && this.uploadFile.name)
            {
                var file = this.uploadImage
                var formData = new FormData()
                formData.append("image", file)
                this.$http.post('/api/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "thumbnail"
                    }
                }).then((res_image) => {
                    var file = this.uploadFile
                    var formData = new FormData()
                    formData.append("image", file)
                    this.$http.post('/api/file/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            type: "attachment"
                        }
                    }).then((res_attachment) => {
                        // 기본 정보 DB 입력
                        this.$http.post("/api/estimate_request/insert", {
                            params: {
                                writer: this.$store.state.asap_user.user_id,
                                category: this.estimate.category,
                                product_name: this.estimate.product_name,
                                pay_date: this.estimate.pay_date,
                                deadline: this.estimate.deadline,
                                memo: this.estimate.memo,
                                thumbnail: res_image.data,
                                attachment: res_attachment.data
                            }
                        }).then(async (res) => {
                            if(!res.data.affectedRows){
                                alert("견적요청을 입력하는 도중 오류가 발생하였습니다")
                            }else{
                                // 해당 분야의 업체 목록 불러오기
                                await this.$http.post('/api/solution/select/category', {
                                    params: {
                                        category: this.estimate.category,
                                        writer: this.$store.state.asap_user.user_id
                                    }
                                }).then((res2) => {
                                    // 해당 분야의 업체들에게 메세지 보내기
                                    let solution_list = res2.data
                                    solution_list.forEach(e => {
                                        this.$http.post('/api/message/insert', {
                                            params: {
                                                type : 'estimate_request',
                                                sender : this.$store.state.asap_user.user_id,
                                                receiver : e.writer,
                                                content : '',
                                                image: null,
                                                attachment: null,
                                                estimate_id: res.data.insertId
                                            }
                                        })
                                    })
                                })

                                alert("견적요청이 작성되었습니다.\n견적요청은 해당 분야 업체로 전송됩니다.")
                                this.$router.push("/mypage/home")
                            }
                        })
                    })
                })
            }

            // 이미지 O & 파일첨부 X
            else if(this.uploadImage.name && !this.uploadFile.name)
            {
                var file = this.uploadImage
                var formData = new FormData()
                formData.append("image", file)
                this.$http.post('/api/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "thumbnail"
                    }
                }).then((res_image) => {
                    // 기본 정보 DB 입력
                    this.$http.post("/api/estimate_request/insert", {
                        params: {
                            writer: this.$store.state.asap_user.user_id,
                            category: this.estimate.category,
                            product_name: this.estimate.product_name,
                            pay_date: this.estimate.pay_date,
                            deadline: this.estimate.deadline,
                            memo: this.estimate.memo,
                            thumbnail: res_image.data
                        }
                    }).then(async (res) => {
                        if(!res.data.affectedRows){
                            alert("견적요청을 입력하는 도중 오류가 발생하였습니다")
                        }else{
                            // 해당 분야의 업체 목록 불러오기
                            await this.$http.post('/api/solution/select/category', {
                                params: {
                                    category: this.estimate.category,
                                    writer: this.$store.state.asap_user.user_id
                                }
                            }).then((res2) => {
                                // 해당 분야의 업체들에게 메세지 보내기
                                let solution_list = res2.data
                                solution_list.forEach(e => {
                                    this.$http.post('/api/message/insert', {
                                        params: {
                                            type : 'estimate_request',
                                            sender : this.$store.state.asap_user.user_id,
                                            receiver : e.writer,
                                            content : '',
                                            image: null,
                                            attachment: null,
                                            estimate_id: res.data.insertId
                                        }
                                    })
                                })
                            })

                            alert("견적요청이 작성되었습니다.\n견적요청은 해당 분야 업체로 전송됩니다.")
                            this.$router.push("/mypage/home")
                        }
                    })
                })
            }

            // 이미지 X & 파일첨부 O 
            else if(!this.uploadImage.name && this.uploadFile.name)
            {
                var file = this.uploadFile
                var formData = new FormData()
                formData.append("image", file)
                this.$http.post('/api/file/upload', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    params: {
                        type: "attachment"
                    }
                }).then((res_attachment) => {
                    // 기본 정보 DB 입력
                    this.$http.post("/api/estimate_request/insert", {
                        params: {
                            writer: this.$store.state.asap_user.user_id,
                            category: this.estimate.category,
                            product_name: this.estimate.product_name,
                            pay_date: this.estimate.pay_date,
                            deadline: this.estimate.deadline,
                            memo: this.estimate.memo,
                            thumbnail: "default.png",
                            attachment: res_attachment.data
                        }
                    }).then(async (res) => {
                        if(!res.data.affectedRows){
                            alert("견적요청을 입력하는 도중 오류가 발생하였습니다")
                        }else{
                            // 해당 분야의 업체 목록 불러오기
                            await this.$http.post('/api/solution/select/category', {
                                params: {
                                    category: this.estimate.category,
                                    writer: this.$store.state.asap_user.user_id
                                }
                            }).then((res2) => {
                                // 해당 분야의 업체들에게 메세지 보내기
                                let solution_list = res2.data
                                solution_list.forEach(e => {
                                    this.$http.post('/api/message/insert', {
                                        params: {
                                            type : 'estimate_request',
                                            sender : this.$store.state.asap_user.user_id,
                                            receiver : e.writer,
                                            content : '',
                                            image: null,
                                            attachment: null,
                                            estimate_id: res.data.insertId
                                        }
                                    })
                                })
                            })

                            alert("견적요청이 작성되었습니다.\n견적요청은 해당 분야 업체로 전송됩니다.")
                            this.$router.push("/mypage/home")
                        }
                    })
                })
            }

            // 이미지 X & 파일첨부 X
            else if(!this.uploadImage.name && !this.uploadFile.name)
            {
                // 기본 정보 DB 입력
                this.$http.post("/api/estimate_request/insert", {
                    params: {
                        writer: this.$store.state.asap_user.user_id,
                        category: this.estimate.category,
                        product_name: this.estimate.product_name,
                        pay_date: this.estimate.pay_date,
                        deadline: this.estimate.deadline,
                        memo: this.estimate.memo,
                        thumbnail: "default.png"
                    }
                }).then(async (res) => {
                        if(!res.data.affectedRows){
                            alert("견적요청을 입력하는 도중 오류가 발생하였습니다")
                        }else{
                            // 해당 분야의 업체 목록 불러오기
                            await this.$http.post('/api/solution/select/category', {
                                params: {
                                    category: this.estimate.category,
                                    writer: this.$store.state.asap_user.user_id
                                }
                            }).then((res2) => {
                                // 해당 분야의 업체들에게 메세지 보내기
                                let solution_list = res2.data
                                solution_list.forEach(e => {
                                    this.$http.post('/api/message/insert', {
                                        params: {
                                            type : 'estimate_request',
                                            sender : this.$store.state.asap_user.user_id,
                                            receiver : e.writer,
                                            content : '',
                                            image: null,
                                            attachment: null,
                                            estimate_id: res.data.insertId
                                        }
                                    })
                                })
                            })

                            alert("견적요청이 작성되었습니다.\n견적요청은 해당 분야 업체로 전송됩니다.")
                            this.$router.push("/mypage/home")
                        }
                    })
            }
        }, 500),
    }
}
</script>
<style scoped>
::v-deep .v-text-field input {
    margin-left:2px;
}
</style>