<template>
    <div style="border-bottom:2px solid #90CAF9">
        <v-sheet
            height="90"
            class="d-flex align-center justify-space-between px-3"
        >
            <!-- 사이드메뉴 -->
            <v-btn
                icon
                @click="drawer = !drawer"
            >
                <v-icon>mdi-menu</v-icon>
            </v-btn>

            <!-- 로고 -->
            <v-sheet 
                class="d-flex justify-center mt-1"
            >
                <v-img
                    height="54"
                    width="120"
                    style="cursor:pointer"
                    contain
                    src="@/assets/logo/default.png"
                    @click="$router.push('/')"
                ></v-img>
            </v-sheet>

            <!-- 계정 -->
            <div>
                <v-icon
                    v-if="!$store.state.asap_user.is_logined"
                    size="28"
                    style="cursor:pointer;"
                    @click="$router.push('/auth/login')"
                >
                    mdi-account-circle-outline
                </v-icon>

                <v-img
                    v-if="$store.state.asap_user.is_logined"
                    class="rounded-circle"
                    style="width:28px; height:28px;"
                    :src="'/upload/user/profile_image/' + $store.state.asap_user.profile_image"
                    @click="$router.push('/mypage/home')"
                ></v-img>
            </div>
        </v-sheet>

        <!-- 사이드메뉴 -->
        <v-navigation-drawer
            v-model="drawer"
            absolute
            temporary
        >
            <v-list
                nav
                class="font-weight-medium px-2"
            >
                <v-list-item
                    v-for="(item, index) in menu" :key="index"
                    :to="item.url"
                    class="px-4"
                    active-class="grey--lighten-2"
                >
                    {{item.title}}
                </v-list-item>
            </v-list>
        </v-navigation-drawer>
    </div>
</template>
<script>
export default {
    data: () => ({
        drawer: false,

        menu: [
            {
                title: "홈",
                url: "/"
            },
            {
                title: "업체 목록",
                url: "/solution"
            },
            {
                title: "고객센터", 
                url: "/cs/faq"
            }
        ]
    }),
}
</script>
<style scoped>
.v-btn::before {
    background-color: transparent;
}

.v-btn:hover {
    color: #2196f3 !important;
}
</style>