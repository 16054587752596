<template>
    <v-sheet
        width="1200"
        class="mx-auto pt-16 px-2"
        color="transparent"
    >
        <!-- 제목 -->
        <p class="text-center mb-2">
            <v-icon
                size="50"
                color="primary"
            >
                mdi-layers-search-outline
            </v-icon>
            <br/>
        </p>
        <p class="text-center text-subtitle-1 font-weight-medium">
            새로 올라온 업체
        </p>

        <!-- 목록 -->
        <v-sheet
            width="950"
            class="rounded-xl mt-10 pa-8 pb-6 mx-auto"
            outlined
        >
            <v-row>
                <v-col
                    v-for="item in list" :key="item.id"
                    cols="4"
                    class="mb-2"
                    @click="read(item)"
                    style="cursor:pointer;"
                >
                    <v-img
                        class="rounded-lg"
                        height="180"
                        :src="item.image"
                    />
                    <p
                        class="ma-1 mb-0 font-weight-medium text-subtitle-1"
                    >
                        {{item.title}}
                        <v-icon class="ml-1" color="red" size="20">mdi-alpha-n-box</v-icon>
                    </p>
                    <p
                        class="mx-1 mb-1 font-weight-medium text-subtitle-2 cyan--text text--darken-2"
                    >
                        {{item.category}}
                    </p>
                    <div
                        class="d-flex justify-space-between mx-1"
                    >
                        <div>

                            <p class="my-0 text-caption" style="line-height:17px;">
                                <span>작성자 : {{item.writer}}</span><br/>
                                <span class="mr-2">{{new Date(item.created).toLocaleDateString().slice(0, -1)}}</span>
                                <span>조회수 {{item.view_count}}</span>
                            </p>
                        </div>
                        <div>
                            <v-btn
                                v-ripple="false"
                                height="36"
                                x-small
                                depressed
                                style="background:white; border:1px solid #ccc;"
                                class="mr-2"
                                color="blue--text text--lighten-3"
                                @click.stop="scrap(item)"
                            >
                                <v-icon v-if="item.is_scraped==0" size="18" color="grey lighten-1">mdi-star</v-icon>
                                <v-icon v-if="item.is_scraped!=0" size="18" color="blue">mdi-star</v-icon>
                            </v-btn>
                            <v-btn
                                v-ripple="false"
                                height="36"
                                x-small
                                depressed
                                style="background:white; border:1px solid #ccc;"
                                class="btn-with-hover"
                                color="grey--text text--lighten-1"
                                @click.stop="newTab(item)"
                            >
                                <v-icon size="18">mdi-content-copy</v-icon>    
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-sheet>
    </v-sheet>
</template>
<script>
export default {
    data: () => ({
        // 목록
        list: []
    }),

    mounted() {
        // 목록 불러오기
        this.load()
    },

    methods: {
        // 목록 불러오기
        load(){
            this.$http.post('/api/solution/select/list', {
                params: {
                    user_id: this.$store.state.asap_user.user_id
                }
            }).then((res) => {
                this.list = res.data.filter(e => e.approval == '승인됨').slice(0,6)
                this.matchThumbnail()
            })
        },

        // 게시글로 이동
        read(item){
            this.$router.push("/solution?type=read&id="+item.id)
        },

        // 썸네일 추가
        matchThumbnail(){
            // 이미지
            const imgReg = /<img[^>]*src=[\"']?([^>\"']+)[\"']?[^>]*>/i

            this.list.forEach(e => {
                if(e.content.match(imgReg))
                {
                    e.image = e.content.match(imgReg)[0].replace(/.*src="([^"]*)".*/, '$1')
                }
            })
        },
    }
}
</script>