<template>
    <v-sheet
        width="950"
        outlined
        class="rounded-10"
    >
        <!-- 본문 -->
        <v-sheet
            class="pa-6 rounded-10"
            @drop.prevent="onDrop" 
            @dragover="checkDrop($event)" 
            @paste="checkPaste($event)"
        >
            <p class="text-h6 mb-3">
                <v-icon color="blue" class="mb-1 mr-1">mdi-layers-edit</v-icon>
                제휴업체 등록하기
            </p>

            <!-- 상단 (카테고리 & 제목) -->
            <div class="d-flex mb-2">
                <!-- 카테고리 -->
                <v-sheet
                    class="pa-1 mt-2 mr-2 rounded-10"
                    width="300"
                    outlined
                >
                    <v-select
                        solo
                        flat
                        dense
                        hide-details
                        :items="select_list.category"
                        placeholder="건설 분야"
                        v-model="category"
                    ></v-select>
                </v-sheet>

                <!-- 제목 -->
                <v-sheet
                    width="100%"
                    class="pa-1 mt-2 rounded-10"
                    outlined
                >
                    <v-text-field
                        hide-details
                        dense
                        placeholder="제목을 입력해주세요"
                        solo
                        flat
                        v-model="title"
                    ></v-text-field>
                </v-sheet>
            </div>

            <!-- 본문 -->
            <TipTapWriter
                class="tiptap_style mb-4"
                :options="options"
            />

            <v-file-input
                outlined
                show-size
                hide-details
                prepend-icon
                color="blue"
                class="rounded-lg"
                label="파일 첨부"
                v-model="uploadFile"
            >
                <template v-slot:prepend-inner>
                    <v-icon class="mr-1" color="blue">mdi-file-chart-outline</v-icon>
                </template>
            </v-file-input>

            <!-- 버튼 -->
            <div class="mt-6 d-flex justify-center">
                <!-- 제출 -->
                <v-btn
                    width="110"
                    height="42"
                    dark
                    depressed
                    color="blue"
                    class="font-weight-bold rounded-lg mr-4"
                    @click="submit()"
                >
                    작성
                </v-btn>

                <!-- 취소 -->
                <v-btn
                    width="110"
                    height="42"
                    depressed
                    color="grey"
                    dark
                    class="font-weight-bold rounded-lg"
                    @click="close()"
                >
                    취소
                </v-btn>
            </div>
        </v-sheet>
    </v-sheet>
</template>
<script>
import TipTapWriter from "@/components/tiptap/Writer"

export default {
    components: {
        TipTapWriter
    },

    data: () => ({
        select_list: {
            category: [
                "가스난방",
                "건설인력",
                "건설자재",
                "건설기계",
                "건축시공",
                "계측/측량",
                "구조물해체/비계공사",
                "도장방수",
                "전기/기계/설비",
                "상하수도",
                "설계",
                "시설물유지관리",
                "시험기구",
                "안전용품",
                "인테리어",
                "조경",
                "지반조성/포장",
                "토목시공",
                "품질검사",
                "기타"
            ]
        },

        writer: "",
        category: "",
        title: "",
        content: "",
        attachment: "",
        view_count: "",
        created: "",

        uploadFile: {},

        //TipTap
        options: {
            content: '',
            editable: true,
            supportImage: true,
            supportVideo: true
        }
    }),

    methods: {
        // Copy & Paste (파일 있을시 파일 업로드 Call)
        checkPaste(e){
            console.log(e)
        },

        // Drag & Drop (기본 이벤트 차단)
        checkDrop(e) {
            e.preventDefault()
        },

        // Drag & Drop (파일 업로드 Call)
        async onDrop(e){
            let file = e.dataTransfer.files[0]
            this.uploadFile = file
        },

        close(){
            this.$router.go(-1)
        },
        
        // 제출
        submit: _.debounce(function() {
            if(this.category == "")
            {
                alert("카테고리를 선택해주세요")
            }
            else if(this.title == "")
            {
                alert("제목을 입력해주세요")
            }
            else
            {
                if(this.uploadFile.name)
                {
                    var file = this.uploadFile
                    var formData = new FormData()
                    formData.append("image", file)
                    this.$http.post('/api/file/upload', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        params: {
                            type: "solution/attachment"
                        }
                    }).then((res_attachment) => {
                        // 업체정보 입력
                        this.$http.post("/api/solution/insert", {
                            params: {
                                writer: this.$store.state.asap_user.user_id,
                                category: this.category,
                                title: this.title,
                                content: this.options.content,
                                attachment: res_attachment.data
                            }
                        }).then((res) => {
                            if(!res.data.affectedRows)
                            {
                                alert("업체정보 작성 도중 오류가 발생하였습니다\n반복 시 고객센터에 문의바랍니다.")
                            }
                            else
                            {
                                alert("업체등록이 신청되었습니다.\n업체정보는 관리자 승인 후 등록됩니다.")
                                this.$router.push("/mypage/home")
                                // this.$router.push("/solution?type=read&id="+res.data.insertId)
                            }
                        })
                    })
                }
                else{
                    // 업체정보 입력
                    this.$http.post("/api/solution/insert", {
                        params: {
                            writer: this.$store.state.asap_user.user_id,
                            category: this.category,
                            title: this.title,
                            content: this.options.content
                        }
                    }).then((res) => {
                        if(!res.data.affectedRows)
                        {
                            alert("업체정보 작성 도중 오류가 발생하였습니다\n반복 시 고객센터에 문의바랍니다.")
                        }
                        else
                        {
                            alert("업체등록이 신청되었습니다.\n업체정보는 관리자 승인 후 등록됩니다.")
                            this.$router.push("/mypage/home")
                            // this.$router.push("/solution?type=read&id="+res.data.insertId)
                        }
                    })
                }
            }
        }, 500)
    }
}
</script>
<style scoped>
.no_under >>> .v-input__slot::before {
  border-style: none !important;
}

.v-btn::before {
    background-color: transparent;
}
</style>